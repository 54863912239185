///////////////////////////////////////
///// Import Custom Variables //////////
//////////////////////////////////////

// Import custom Bootstrap variables
@import "bootstrap-variables";

@import "variables";

///////////////////////////////////////
///// Editor Styles ///////////////////
//////////////////////////////////////

body, td {
  font-family: $secondary_font;
  font-size:   14px;
  color:       $copy_text_color;
  line-height: 18px;
}

h1, h2, h3, #mainNav li a {
  font-family:    $primary_font;
  letter-spacing: 0;
  font-style:     normal;
}

h1 {
  font-size:   30px;
  line-height: 34px;
  color:       $theme_primary_colour;
  font-weight: bold;
  margin:      0 0 20px 0;
}

h2 {
  font-size:   24px;
  line-height: 30px;
  color:       $theme_primary_colour;
  font-weight: bold;
  margin:      0 0 10px 0;
}

h3 {
  font-size:   20px;
  line-height: 24px;
  color:       $theme_primary_colour;
  font-weight: bold;
  margin:      0 0 10px 0;
}

h4 {
  font-size:   14px;
  line-height: 17px;
  color:       lighten($copy_text_color, 10%);
  font-weight: bold;
}

a {
	color:           $prim_color;
	text-decoration: none;
	outline:         none;
  &:visited, &:active {
	color:           $prim_color;
	text-decoration: none;
	outline:         none;
  }
  &:hover {
	color:           lighten($prim_color, 15%);
	text-decoration: none;
  }
}

hr {
  margin:     20px 0;
  height:     1px;
  border:     none;
  border-top: 1px solid #CCCCCC;
  color:      #CCCCCC;
}

/*
	Custom Styles for dropdown
	Notice [10/02/2012]: TinyMCE applies css classnames in lowercase when used within a Webkit browser. So 'textHighlight' becomes 'texthighlight'.
	Workaround: Either declare all editor styles in lowercase, or create duplicate lowercase styles for webkit browsers.
*/

.button {
  -webkit-backface-visibility: hidden;
}
.btn {
  -webkit-backface-visibility: hidden;
}

.scale_with_grid {
  height: auto;
}

.text_highlight {
  font-weight: bold;
  color:       #999999;
}

.text_highlight2 {
  font-weight: bold;
  color:       #006600;
}

.pageimageright {
  float:              right;
  margin:             0px 3px 10px 25px;
  border:             solid 5px #FFFFFF;
  border-radius:      3px;
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
  -moz-box-shadow:    0px 0px 7px rgba(0, 0, 0, 0.4);
  box-shadow:         0px 0px 7px rgba(0, 0, 0, 0.4);
}

.pageimageleft {
  float:              left;
  margin:             0px 25px 10px 3px;
  border:             solid 5px #FFFFFF;
  border-radius:      3px;
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
  -moz-box-shadow:    0px 0px 7px rgba(0, 0, 0, 0.4);
  box-shadow:         0px 0px 7px rgba(0, 0, 0, 0.4);
}

/* ******************* */
/* Lists */
/* ******************* */


.content ul, .content ol {
  list-style: none;
  padding:    0px;
  margin:     0px 10px 15px;
}

.content ul li {
  text-indent: 0;
  margin-left: 10px;
  padding:     3px 0px;
}

.copy {

  p img {
	max-width:  100%;
	height:     auto;
	max-height: 100%
  }

  ul, ol {
  	list-style: none;
    padding: 0px;
    margin: 0px 0px 20px 0px;
    counter-reset: li-counter;

    li {
      margin-left: 35px;
      padding: 4px 0px;
      position: relative;
      text-indent: 0px;

      &:before {
        position: relative;
        left: -20px;
        display: inline-block;
        color: $prim_color;
      }
    }
  }

  ul li:before {
    content: '';
    background: transparent;
    border: 2px solid $prim_color;
    width: 5px;
    height: 5px;
    margin-right: -9px;
    border-radius: 50%;
  }

  ol li:before {
  	font-weight: bold;
    width: 4em;
    margin-right: -4em;
    counter-increment: li-counter;
    content: counter(li-counter) ".";
  }
}

/* ******************* */
/* Tables */
/* ******************* */

.nice_table {}

table.nice_table {
  width:  100% !important;
  margin: 20px 0px;

  thead {

  	tr {
  	  background: $prim_color;

  	  th, td {
    		font-weight:  bold;
    		padding:      14px 14px 14px 15px;
    		font-size:    14px;
        line-height:  1.4;
    		text-align:   left !important;
    		color:        #FFFFFF;
    		/*background:   url("images/backgrounds/table-row-divider-bg.png") no-repeat bottom right;*/
    		border-right: solid 1px rgba(0, 0, 0, 0.05);
        font-family: Montserrat, 'Helvetica Neue', Helvetica, Arial, sans-serif;

        &:last-child {
          border-right-color: $prim_color;
        }
  	  }
  	}
  }
  tbody {
    tr {

    	&:nth-child(even) {
    	  background-color : #FCFCFA;
    	}
    	td {
    	  border-bottom:  1px solid #D8D8D8;
    	  text-align:     center;
    	  padding:        14px 14px;
    	  text-align:     left !important;
    	  vertical-align: middle !important;
    	  border-right:   solid 1px #D8D8D8;
        color:          #424846;

    	  &:first-child {
    		border-left: solid 1px #D8D8D8;
    	  }
    	  &:last-child {
    		border-right: solid 1px #D8D8D8;
    	  }
    	  p {
    		padding: 0px;
    		margin:  0px !important;
    	  }
    	}

    	&.rowDivider {

    	  td {
    		border-top: 3px solid #333333;
    	  }
    	}
    }
  }
}

table.invisibleTable, table.gsc-search-box {
	tr, td {
	  border:         0;
	  background:     transparent !important;
	  vertical-align: top !important;
	}
}
table.invisibleTable tr, table.invisibleTable td {
  padding: 5px;
}