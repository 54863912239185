////////////////////////////////////////
///// Theme Variables //////////////////
////////////////////////////////////////

// Colours, Shades & Font Colours

$prim_color: #3cc7de;
$copy_text_color: #666;

// Font Family and Font Base

$primary_font: $font-family-sans-serif;
$secondary_font: 'PT Sans', sans-serif;


// Theme Variables

// Asset Path's
$image_path: '/assets/images/';

// Logo
$main_logo: '/images/logos/gold-coast-oms-logo.png';
$main_logo_width: 162px;
$main_logo_height: 81px;

// Header Variable Settings
$header_height: 142px;
$header_max_height: 300px;

// Theme Branding Colours
$theme_primary_colour: #3cc7de;
$theme_secondary_colour: darken($theme_primary_colour, 15%); // #56B3D2;
$theme_tertiary_colour: #67351f;

$theme_offset_gray: #FAFCFB;

// Theme Font Colours
$theme_text_colour_grey: #999999;
$theme_text_colour_alt_1: darken($theme_primary_colour, 15%);


// Banner
$home_banner_height: 630px;
$secondary_banner_height: $home_banner_height; // 376px;

// Secondary Template
$sidebar_cta_banner_min_height: 376px;

// Overwrite bootstap colours - To make colour changing easier
$btn-primary-color: $theme_primary_colour;
$btn-default-bg: $theme_tertiary_colour;
$brand-primary: $theme_tertiary_colour;
$input-border-focus: $theme_tertiary_colour;
$navbar-default-link-color: $theme_primary_colour;
$navbar-default-link-hover-color: $theme_secondary_colour;
$navbar-default-link-active-color: $theme_secondary_colour;
$navbar-default-toggle-icon-bar-bg: $theme_tertiary_colour;
$navbar-default-toggle-border-color: $theme_tertiary_colour;
